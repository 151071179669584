import UserStats from "./UserStats";

class UserStatisticDataHelper {
  userStats = []
  userStatsIndexes = {}
  userStatsIndexesByGame = {
    game1_total_win: [],
    game1_avg_odd: [],
    game1_percentage: [],
    game2_total_win: [],
    game2_avg_odd: [],
    game2_percentage: [],
    game3_total_win: [],
    game3_avg_odd: [],
    game3_percentage: [],
    game4_total_win: [],
    game4_avg_odd: [],
    game4_percentage: []
  }
  games = {
    match_outcome: 'game1',
    match_goals: 'game2',
    both_team_goals: 'game3',
    half_time_full_time: 'game4',
  }
  rates = {
    total_win: 'total_win',
    average_odd: 'avg_odd',
    percentage: 'percentage'
  }
  selectedGameRate = null
  

  constructor(userStats) {
    this.setUserStats(userStats)
    this.initialSort()
    this.setSelectedGameRate(this.games.match_outcome, this.rates.total_win)
  }

  setUserStats = (userStats) => {
    userStats.forEach((stat) => {
      if(! this.userStatsIndexes[`stat_${stat.user_id}`]) {
        this.userStatsIndexes[`stat_${stat.user_id}`] = new UserStats(stat)
        this.userStats.push(this.userStatsIndexes[`stat_${stat.user_id}`])
      }
    })
  }
  
  initialSort = () => {
    this.sortByGameRate(this.games.match_outcome, this.rates.total_win)   
    this.sortByGameRate(this.games.match_outcome, this.rates.average_odd)
    this.sortByGameRate(this.games.match_outcome, this.rates.percentage)
    this.sortByGameRate(this.games.match_goals, this.rates.total_win)   
    this.sortByGameRate(this.games.match_goals, this.rates.average_odd)
    this.sortByGameRate(this.games.match_goals, this.rates.percentage)
    this.sortByGameRate(this.games.both_team_goals, this.rates.total_win)   
    this.sortByGameRate(this.games.both_team_goals, this.rates.average_odd)
    this.sortByGameRate(this.games.both_team_goals, this.rates.percentage)
    this.sortByGameRate(this.games.half_time_full_time, this.rates.total_win)   
    this.sortByGameRate(this.games.half_time_full_time, this.rates.average_odd)
    this.sortByGameRate(this.games.half_time_full_time, this.rates.percentage)
  }

  // sortByGame = (game) => {
  //   this.sortByGameRate(game, this.rates.total_win)
  // }

 
  sortByGameRate = (game, rate, asc = false) => {
    let tempArray = [...this.userStats]

    tempArray.sort((a, b) => {
      if (isNaN(Number(a[`${game}_${rate}`]))){
        return 1;
        } else if (isNaN(Number(b[`${game}_${rate}`]))) {
        return -1;
       } 
      if(Number(a[`${game}_${rate}`]) > Number(b[`${game}_${rate}`])) {
        return (asc ? 1 : -1)
      }

      if(Number(a[`${game}_${rate}`]) < Number(b[`${game}_${rate}`])) {
        return (asc ? -1 : 1)
      }

      return 0
    })

    this.userStatsIndexesByGame[
      `${game}_${rate}`] = tempArray

  }

  setSelectedGameRate = (game,rate, isAsc) => {
    this.sortByGameRate(game, rate, isAsc)
    this.selectedGameRate = {
      game: game,
      rate: rate,
      data: this.userStatsIndexesByGame[`${game}_${rate}`]
    }
  }

}

export default UserStatisticDataHelper