import React from "react";
import '../../../style/components/common/bb_divider.scss'
import PropTypes from "prop-types";

function BB_Divider(props) {

  if(props.hidden) {
    return null
  }

  return (
      <div className={'divider-container'}>
        <div className={'divider-label'}>{props.label}</div>
      </div>
  )
}
BB_Divider.propTypes = {
  label: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
};

BB_Divider.defaultProps = {
  hidden: false
}

export default BB_Divider