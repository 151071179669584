import React from 'react';
import '../../../style/betbook/today_fixtures.scss';
import Loader from "../../components/other/Loader";
import FooterContainer from "../../components/containers/FooterContainer";
import LeagueShort from "../../components/objectcontrols/LeagueShort";
import FullContainer from "../../components/containers/FullContainer";
import searchSVG from '../../../style/betbook/assets/images/search---final.svg';
import calendarSVG from '../../../style/betbook/assets/images/ball.svg';
import BB_SmallCalendar from "../../components/controls/BB_SmallCalendar";
import SmartContainer from "../../components/containers/SmartContainer";
import SearchContainer from "../../components/containers/SearchContainer";

class TodayFixtures extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            realData: null,
            loaded: false,
            calendarVisible: false,
            currentDate: new Date()
        };

        this.allLeagues = null;
    }

    componentDidMount = () => {
        this.getAllFixtures();
    };

    getAllFixtures = () => {
        window.apiHelper.leagues.getAll(this.state.currentDate.toISOString().slice(0, 10), (res) => {
            this.matchBids(res);
            this.sortLeagues(Object.values(res.leagues));
            this.allLeagues = Object.values(res.leagues);
        })


    };

    sortFavs = (favs) => {
        favs.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
            if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
            return 0;
        });
    };

    sortNonFavs = (leagues) => {
        leagues.sort(function (a, b) {
            if (a.name && a.name.toLowerCase() > b.name.toLowerCase()) return 1;
            if (a.name && a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            return 0;
        });
    };

    matchBids = (res) => {
        let bids = Object.values(res.bids);
        let leagues = Object.values(res.leagues);
        const realData = [];
        bids.forEach(bid => {
            if (bid.fixture) {
                leagues.forEach(league => {
                    league.fixture.forEach(fixture => {
                        if (bid.fixture.id == fixture.id) {
                            realData.push(bid);
                        }
                    })
                })
            }
        });

        this.setState({realData});

    };

    sortLeagues = (res) => {
        let fav_leagues = [];
        let nonfav_leagues = [];
        res.forEach(league => {
            if (league.user_favourite_league) {
                let exists = false;
                let userFavoriteLeague = Array.isArray(league.user_favourite_league) ? league.user_favourite_league : [league.user_favourite_league];
                userFavoriteLeague.forEach(fav => {
                    if (fav.user_id == window.apiHelper.userInfo.id) {
                        league.user_favourite_league = fav;
                        fav_leagues.push(league);
                        exists = true;
                    }
                });
                if (exists == false) {
                    league.user_favourite_league = null;
                    nonfav_leagues.push(league);
                }
            } else nonfav_leagues.push(league);
        });
        this.sortFavs(fav_leagues);
        this.sortNonFavs(nonfav_leagues);
        let realData = fav_leagues.concat(nonfav_leagues);
        this.setState({loaded: true,
                             realData: {
                                leagues: realData,
                                bids: this.state.realData
                             }});
    };

    onCalendarDateClick = (date) => {
        this.setState({calendarVisible: false, loaded: false});
        this.state.currentDate = date;
        this.getAllFixtures();
    };

    onStarClick = (league, i, user_favourite_league) => {
        const realData = [...this.state.realData.leagues];
        if (league.user_favourite_league) {
            realData[i].user_favourite_league = null;
        } else {
            realData[i].user_favourite_league = {
                id: user_favourite_league,
                league_id: league.id,
                user_id: window.apiHelper.userInfo.id
            };
        }
        this.setState({realData: {
                             leagues: realData,
                             bids: this.state.realData.bids
            }})
    };

    onWrapperClick = () => {
        this.setState( {calendarVisible: false} );
    };

    getBids = (league) => {
        const leagueFixtures = league.fixture; // array of fixtures in league object
        const bids = [];
        this.state.realData.bids.forEach(bid => {
            // bids.push(bid.fixture);

            leagueFixtures.forEach(leagueFixture => {
               if(leagueFixture.id === bid.fixture.id) {
                   bids.push(bid);
               }
            });
        });

        return bids;
    };

    handleOnValueChange = (value) => {
        let leagues = this.allLeagues.filter((league) => {
            return league.name.toLowerCase().indexOf(value.toLowerCase()) > -1
        })

        leagues.sort((a, b) => {
            if( a.user_favourite_league) {
                return -1
            } else if(b.user_favourite_league) {
                return 1
            }
        })

        this.state.realData.leagues = leagues

        this.forceUpdate()
    }

    render() {

        if (this.state.loaded) {
            return (
                <div>

                <SmartContainer showFooter={true} showHeader={false} footerProps={{activeItem: 'timeline'}} headerProps={{
                    title: 'All Fixtures', rightIcon: calendarSVG, rightIconOnClick: () => {
                        this.setState({calendarVisible: true})
                    }
                }}>
                    <BB_SmallCalendar show={this.state.calendarVisible} onDateClick={this.onCalendarDateClick}
                                      onClose={this.onWrapperClick}/>
                    <div className='tf_main-content today_fixtures' style={{paddingTop: '60px', paddingBottom: '60px'}}>
                        <>
                            {this.state.realData.leagues.map((league, i) => {
                                return (
                                    <LeagueShort
                                        isChecked={league.user_favourite_league}
                                        className={league.user_favourite_league ? 'star star_checked' : 'star'}
                                        onStarClick={(user_favorite_league) => this.onStarClick(league, i, user_favorite_league)}
                                        key={league.id}
                                        league={league}
                                        bids={this.getBids(league)}
                                    />
                                );
                            })}
                        </>
                    </div>

                </SmartContainer>
                    <SearchContainer onValueChange={this.handleOnValueChange}/>
                </div>
            );
        }else {
            return <Loader/>
        }
    }
}

export default TodayFixtures;
