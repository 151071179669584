import React, {useState, useRef} from 'react'
import '../../../style/components/controls/bb_search.scss'
import {AiOutlineSearch, AiOutlineClose} from "react-icons/ai";

function BB_Search(props) {
    const [showClearButton, setShowClearButton] = useState(false)
    const [value, setValue] = useState('')
    const inputRef = useRef()
    let classes = props.hidden ? 'search_container hidden' : 'search_container'

    const onValueChange = (event) => {
        let newValue = event.target.value
        setValue(newValue)
        setShowClearButton(newValue.length > 0)
        props.onChange(newValue)
    }

    const handleClearButton = () => {
        setValue('')
        inputRef.current.focus()
        props.onChange('')
    }

    return (
        <div className={classes}>
            <div className={'search_icon_container'}>
                <AiOutlineSearch size={20}/>
            </div>
            <input
                ref={inputRef}
                type="text"
                placeholder={'Search league ...'}
                onChange={onValueChange}
                value={value}
            />
            {
                value ? <div className={'clear_search_button'}>
                    <AiOutlineClose onClick={handleClearButton} size={20}/>
                </div> : null
            }
        </div>
    )
}

export default BB_Search