import React, {Component} from "react";
import PropTypes from 'prop-types';
import '../../../style/components/containers/search_container.scss'

class SearchContainer extends Component{
    constructor(props) {
        super(props)

        this.state = {
            searchValue: ""
        }
    }



    handleOnClick = () => {
        this.setState({searchValue: ""})
        this.state.searchValue = ""
        this.props.onValueChange(this.state.searchValue)
    }

    handleOnChange = (event) => {
        let curValue = event.target.value
        this.setState({searchValue: curValue})
        this.props.onValueChange(curValue)
    }

    render() {
        return(
            <div className='sc_main_box'>
                <input className='sc_search_box' onChange={this.handleOnChange} type="text" placeholder="Search..." value={this.state.searchValue}/>
                <span className='sc_close_button_box' onClick={this.handleOnClick}>X</span>
            </div>
        )
    }
}

SearchContainer.propTypes = {
    onValueChange: PropTypes.func
}

export default SearchContainer