import React, {Component} from "react";
import Loader from "../../components/other/Loader";
import "../../../style/betbook/user/leaderboards.scss";
import SmartContainer from "../../components/containers/SmartContainer";
import MainContainer from "../../components/containers/MainContainer";


class Leaderboards extends Component {

    constructor(props) {
        super(props)

        this.state = {
            realData: null,
            loaded: false,
            league_id: props.match.params.league_id ? props.match.params.league_id : null,
            selectedGame: 'game1',
            sortedCol: 'total_win',
            sortedFunc: 'desc'
        }
    }

    componentDidMount() {
        this.getAllStatistics()
    }

    statsCallBack = (res) => {
        res.forEach(item => {
            window.apiHelper.statistics.calculateStatistics(item);
        })
        this.setState({realData: res, loaded: true});
    }

    getAllStatistics = () => {
        if(this.state.league_id != null){
            window.apiHelper.statistics.statisticsByLeague(this.state.league_id, this.statsCallBack);
        }
        else{
            window.apiHelper.statistics.allStatistics(this.statsCallBack);
        }
    }


    sortStatistic = () => {
        const game = this.state.selectedGame;
        const valuesToSort = this.state.sortedCol;

        let realData = this.state.realData.sort((a, b) => {
            if (a[game + '_' + valuesToSort] < b[game + '_' + valuesToSort])
                return -1;
            else if (a[game + '_' + valuesToSort] > b[game + '_' + valuesToSort])
                return 1;
            return 0;
        })

        if(this.state.sortedFunc == 'desc'){
            realData = realData.reverse();
        }


        return realData;
    }


    handleOnTHClick = (col, func) => {
        this.setState({sortedCol: col, sortedFunc: func == 'desc' ? 'asc' : 'desc'})
    }

    renderGameTab = (game, label) => {
        return <div className={this.state.selectedGame == game ? 'lb_stats_box lb_stats_box_active' : 'lb_stats_box'} onClick={() => {
            this.setState({selectedGame: game, sortedCol: 'total_win', sortedFunc: 'desc'})
        }}><span className='lb_stats_text'>{label}</span>
        </div>
    }

    renderStatistics = (game) => {
        const data = this.sortStatistic();

        return (
            <SmartContainer showHeader={true} showFooter={true} footerProps = {{activeItem: 'profile'}} headerProps = {{
                showBack : true,
                title : 'Leaderboards',
                rightIconOnClick: () => { window.location = '#/search' }
            }}>
            <MainContainer>
            <div className='bb_leaderboards'>
                <div className='lb_stats_games'>
                    {this.renderGameTab('game1', 'MO')}
                    {this.renderGameTab('game2', 'TG')}
                    {this.renderGameTab('game3', 'BTG')}
                    {this.renderGameTab('game4', 'HTG')}
                </div>
                <table style={{width: '100%'}}>
                    <thead className='lb_tabel_title_text'>
                    <tr>
                        <th>Name</th>
                        <th onClick={() => this.handleOnTHClick('total_win', this.state.sortedFunc) }>N.
                        </th>
                        <th onClick={() => this.handleOnTHClick('avg', this.state.sortedFunc) }>Avg.
                        </th>
                        <th onClick={() => this.handleOnTHClick('success', this.state.sortedFunc) }>S.
                        </th>
                    </tr>
                    </thead>
                    <tbody className='lb_tabel_field_text'>
                    {data.map(item => <tr>
                        <td>{item.full_name}</td>
                        <td>{item[game + '_total_win']} / {item[game + '_total']}</td>
                        <td>{item[game + '_avg'].toFixed(2)}</td>
                        <td>{item[game + '_success'].toFixed(0)} %</td>
                    </tr>)}
                    </tbody>
                </table>
            </div>
            </MainContainer>
            </SmartContainer>
        )
    }

    render() {
        if (this.state.loaded) {
            return this.renderStatistics(this.state.selectedGame)
        } else {
            return <Loader/>
        }
    }
}

export default Leaderboards