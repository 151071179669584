import React from 'react';
import Footer from "../menus/Footer";
import Header from "../menus/Header";
import './../../../style/components/containers/full_container.scss';
import MainContainer from "../../components/containers/MainContainer";

function FullContainer(props) {
    return (<div className='full_container'>
        <Header {...props.headerProps} />
        <MainContainer>
            <div className='full_container_child'>

                {props.children}

            </div>
        </MainContainer>
        <Footer key='footerContainer' {...props.footerProps} />
    </div>);
}

export default FullContainer;