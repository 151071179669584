import React from 'react';
import '../../../style/components/containers/main_container.scss'

function MainContainer(props) {
  let rand = Math.ceil(Math.random() * 4)
  let classes = `bs-login bg_img_${rand}`

   return  <div className={classes}><div id={'main-container'} className='main-container'>
        {props.children}
    </div></div>
}

export default MainContainer;
