import React, {Fragment} from "react";
import '../../../../../style/components/objectcontrols/leaderboards/leaderboards.scss'
import BB_Avatar from "../../../common/BB_Avatar";

function LB_User_Row({UserStat, Place}) {
  let color = '#fff'
  let fullName = UserStat.fullName
  let numberOfBets = UserStat.numberOfBets
  let totalScore = UserStat.totalScore

  if(Place == 1) {
    color = '#FFD700'
  } else if(Place == 2) {
    color = '#C0C0C0'
  } else if(Place == 3) {
    color = '#CD7F32'
  }

  return (
      <div className={'lb-row'}>
        <div style={{color: color}}>{Place}</div>
        <BB_Avatar />
        <div className={'lb-user-info'}>
          <div>{fullName}</div>
          <div style={{fontWeight: 100}}>{`Number of bets: ${numberOfBets}`}</div>
          <div style={{fontWeight: 100}}>{`Total score: ${parseFloat(totalScore).toFixed(2)}`}</div>
        </div>
      </div>
  )
}

function Leaderboards({UserStats}) {

  return (
      <div className={'lb-wrapper'}>
        {
          UserStats.map((Stat, index) => {
            if (index < 5) {
              return (
                  <Fragment>
                    <LB_User_Row UserStat={Stat} Place={index + 1}/>
                  </Fragment>
              )
            }
        })
        }
      </div>
  )
}

  export default Leaderboards