import React from 'react';
import FixtureShortBids from '../../components/objectcontrols/FixtureShortBids';
import {Link} from "react-router-dom";
import '../../../style/betbook/week-games.scss';
import Loader from "../../components/other/Loader";
import FullContainer from "../../components/containers/FullContainer";
import timelineSVG from '../../../style/betbook/assets/images/timeline.svg';

class UserLeaguesBids extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            realData: [],
            loaded: false
        };
        this.leagudId = props.match.params.leagueid;
    }

    componentDidMount() {
        this.getAllFixturesBided();
    }

    getAllFixturesBided(){
        window.apiHelper.leagues.getByIDBidded(this.leagudId,window.apiHelper.userInfo.id,(res) =>{
            this.setState({realData:res,loaded:true})});
    }

    renderGames = () => {
            return <>
                {this.state.realData.bids.map((fixture) => {
                    if(fixture.fixture) {
                        return <Link to={`/fixture/${fixture.fixture.id}`} key={fixture.fixture.id}>
                            <FixtureShortBids {...fixture}/></Link>
                    } else {
                        return null
                    }
                })}
            </>
    };

    handleOnRightIconClick = (league_id) => {
        window.location.hash = `/leaderboards/${league_id}`
    }

    render() {

        if(this.state.loaded) return (
           <FullContainer  footerProps={{activeItem: 'ball'}}
                           headerProps={{title: this.state.realData.league.name, rightIcon: timelineSVG, rightIconOnClick: () => this.handleOnRightIconClick(this.state.realData.league.id)}}>
                <div className='main-content'>
                    {this.renderGames()}
                </div>
           </FullContainer>
        );

        else {
            return <Loader/>
        }
    }
}

export default UserLeaguesBids;
