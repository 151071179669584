import React, {Fragment, useState} from 'react'
import '../../../../../style/betbook/screens/v2/home/controls/leagues.scss';
import {AiOutlineArrowRight} from "react-icons/ai"
import {Link} from "react-router-dom";
import {BsFillBarChartFill} from "react-icons/bs";

function League({League, Date}) {
  const [isAddFavoriteAvailable, setIsAddFavoriteAvailable] = useState(true)
  const [isFavorite, setIsFavorite] = useState(League.is_favorite)
  const [showStatistic, setShowStatistic] = useState(false)

  const renderFavoriteLeague = () => {
    let classes = isFavorite ? 'star star-checked' : 'star'
    return (
        <div className='bla' onClick={(e) => handleChangeFavoriteState(e)}>
          <svg className={classes} viewBox="0 0 27 26" xmlns="http://www.w3.org/2000/svg" height='20px'
               stroke='#f68c25' width='20px' fill='none'>
            <path d="M19.2763 15.8151L26.5625 9.73236L17.985 9.12409C17.6776 9.09367 17.4009 8.91119 17.2779 8.63747L13.2812 0L9.28458 8.63747C9.1616
                            8.91119 8.88491 9.09367 8.57747 9.12409L0 9.73236L7.28624 15.8151C7.53219 16.028 7.65516 16.3625 7.56293 16.6667L5.19567 25L12.8508 20.1034C13.1275
                            19.9209 13.4965 19.9209 13.7731 20.1034L21.4283 25L19.0611 16.6363C18.9073 16.3625 19.0303 16.028 19.2763 15.8151Z"/>
          </svg>
        </div>
    )
  }

  const handleChangeFavoriteState = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (!isAddFavoriteAvailable) {
      return
    }

    setIsAddFavoriteAvailable(false)

    isFavorite ? removeFavouriteLeague(event) : addFavouriteLeague(event)
  }

  const addFavouriteLeague = (event) => {
    setIsFavorite(true)
    window.apiHelper.user.favourite_team_leagues(window.apiHelper.userInfo.id, League.id, (res) => {
      League.user_favourite_leagues.push({
        id: res,
        user_id: window.apiHelper.userInfo.id,
        league_id: League.id
      })
      setIsAddFavoriteAvailable(true)
    });
  }

  const removeFavouriteLeague = (event) => {
    setIsFavorite(false)
    window.apiHelper.favourites.delete(League.user_favourite_leagues[0].id, () => {
      League.user_favourite_leagues = []
      setIsAddFavoriteAvailable(true)
    });

  }


  const manageScroll = () => {
    let todayFixtureElement = document.getElementById('tf_main-content')
    let todayFixtureScroll = todayFixtureElement.scrollTop

    let mainElement = document.getElementById('main-container')
    let mainScroll = mainElement.scrollTop

    let scrolls = {
      main: mainScroll,
      today_fixture: todayFixtureScroll
    }

    window.localStorage.setItem('scrolls', JSON.stringify(scrolls))

  }

  const handleOnShowStatistic = (event) => {
    event.preventDefault()
    event.stopPropagation()

    League.setShowStatistic(League)
    setShowStatistic(! showStatistic)
  }

  return (
      <Fragment>
        <Link to={`/league/${League.id}?date=${Date.toISOString().slice(0, 10)}`}>
          <div onClick={manageScroll} className={'league_container'}>
            <div className={'logo_name_container'}>
              <div className={'logo_container'}>
                <img
                    src={"./assets/images/Logos/" + League.logo}
                    alt={'Logo'}
                />
              </div>
              <div className="name_container">
                <div className={'country_name'}>{League.country.name}</div>
                <div className={'league_name'}>{League.name}</div>
                <div className={'total_fixtures'}>{`Fixtures count: ${League.total_fixtures}`}</div>
              </div>
            </div>
            <div onClick={(event) => event.stopPropagation()} className={'favorite_container'}>
              {renderFavoriteLeague()}
              <div className={'show_fixtures'} onClick={(event) => event.stopPropagation()}>
                <Link to={`/leaderboards`}>
                  <BsFillBarChartFill color={'#f68c25'} size={25}/>
                </Link>
              </div>
              <div
                  onClick={handleOnShowStatistic}
                  className={showStatistic ? 'show_statistic rotate' : 'show_statistic'}
              >
                <AiOutlineArrowRight color={'#f68c25'} size={25}/>
              </div>
            </div>
          </div>
        </Link>
      </Fragment>
  )
}

export default League