import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import '../../../style/components/controls/bb_small_calendar.scss';


function BB_SmallCalendar(props) {
  const name = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const today = new Date();
  const days = []


  for (let i = 0; i <= 2; i++) {
    const date = new Date();
    date.setDate(today.getDate() + i)
    days.push(date)
  }

  const renderDateElement = (date, index) => {
    let dayShort = index == 0 ? 'Today' : name[date.getDay()]
    let dateString = date.toLocaleDateString(undefined, {month: 'numeric', day: 'numeric'})

    return (
      <div className={props.selected.getDay() == date.getDay() && props.selected.getMonth() == date.getMonth() ? 'ft_home-field selected' : 'ft_home-field'} onClick = {() => props.onTodayClick(date)}>
            <div>
          <div>{dayShort}</div>
          <div>{dateString}</div>
        </div>
      </div>
    )
  }

  return (

    <div className='small_calendar_wrapper'>
      {days.map((day, index) => {
        return (
          renderDateElement(day, index)
        )
      })}
    </div>

  );
}

BB_SmallCalendar.propTypes = {
  onDateClick: PropTypes.func,
};

export default BB_SmallCalendar;