import React, {Fragment, useState} from "react";
import '../../../../../style/betbook/objectcontrols/v2/statistic/user_league_statistic.scss'
import UserStatisticDataHelper from "./helpers/UserStatisticDataHelper";
import UserProfile from "../../../../screens/user/UserProfile";


function UserStatisticV2({Stats}) {
  const dataHelper = new UserStatisticDataHelper(Stats)
  const gameButtons = [
    {
      label: 'MO',
      key: dataHelper.games.match_outcome
    },
    {
      label: 'MG',
      key: dataHelper.games.match_goals
    },
    {
      label: 'BTG',
      key: dataHelper.games.both_team_goals
    },
    {
      label: 'HT/FT',
      key: dataHelper.games.half_time_full_time
    },
  ]
  const ratesButtons = [
    {
      label: 'TW',
      key: dataHelper.rates.total_win
    },
    {
      label: 'AO',
      key: dataHelper.rates.average_odd
    },
    {
      label: 'PERC',
      key: dataHelper.rates.percentage
    }
  ]
  const [selectedGame, setSelectedGame] = useState(dataHelper.selectedGameRate)
  const [ascOrder, setAscOrder] = useState(false)
  const [selectedGameButton, setSelectedGameButton] = useState(gameButtons[0])

  const handleGameChange = (game, index) => {
    dataHelper.setSelectedGameRate(game.key, selectedGame.rate)
    setSelectedGame(dataHelper.selectedGameRate)
    setSelectedGameButton(gameButtons[index])
  }

  const handleRateChange = (rate) => {
    setSelectedGame(selectedGame)
    dataHelper.setSelectedGameRate(selectedGame.game, rate.key, ascOrder)
    setAscOrder(! ascOrder)
    setSelectedGame(dataHelper.selectedGameRate)
  }


  return (
      <div className={'us_wrapper'}>
        <div className={'game_rates_row'}>
          <div style={{width:'10%'}}>#</div>
          <div style={{width:'30%'}}>Name</div>
          {
            ratesButtons.map((button, index) => {
              return (
                  <div key={`${button.key}_${index}`} onClick = {() => handleRateChange(button)}>{button.label}</div>
              )
            })
          }

        </div>
        <div className={'game_stats_wrapper'}>
          {
            selectedGame.data.map((game,index) => {
              let gameIndexTotalWin = `${selectedGame.game}_total_win`
              let gameIndexTotal = `${selectedGame.game}_total`
              let avgOddIndex = `${selectedGame.game}_avg_odd`
              let percentageIndex = `${selectedGame.game}_percentage`

              if(index < 5) {           
                return (
                    <div className={'game_stats_row'}>
                      <div className={window.apiHelper.userInfo.id == game.user_id ? 'game_stats_row color' : 'game_stats_row'} style={{width:'10%'}}>{index + 1}</div>
                      <div className={window.apiHelper.userInfo.id == game.user_id ? 'game_stats_row color' : 'game_stats_row'} style={{width:'30%'}}>{game.full_name}</div>
                      <div className={window.apiHelper.userInfo.id == game.user_id ? 'game_stats_row color' : 'game_stats_row'}>{`${game[gameIndexTotalWin]} / ${game[gameIndexTotal]}`}</div>
                      <div className={window.apiHelper.userInfo.id == game.user_id ? 'game_stats_row color' : 'game_stats_row'}>{game[avgOddIndex]}</div>
                      <div className={window.apiHelper.userInfo.id == game.user_id ? 'game_stats_row color' : 'game_stats_row'}>{`${game[percentageIndex]}%`}</div>
                    </div>
                )
              }
              else if (index >= 5 && index < selectedGame.data.length && window.apiHelper.userInfo.id == game.user_id) {
                return (
                    <div className={'game_stats_row'}>
                      <div className={'game_stats_row color'} style={{width:'10%'}}>{index + 1}</div>
                      <div className={'game_stats_row color'} style={{width:'30%'}}>{game.full_name}</div>
                      <div className={'game_stats_row color'}>{`${game[gameIndexTotalWin]} / ${game[gameIndexTotal]}`}</div>
                      <div className={'game_stats_row color'}>{game[avgOddIndex]}</div>
                      <div className={'game_stats_row color'}>{`${game[percentageIndex]}%`}</div>
                    </div>
                )
              }

              return null
            })
          }
        </div>
        <div className={'game_buttons_row'}>
          {
            gameButtons.map((button, index) => {
              let classes = button.key == selectedGameButton.key ? 'ft_home-field timeline selected' : 'ft_home-field timeline'
              return (
                <div className={classes} key={`${button.key}_${index}`} onClick = {() => handleGameChange(button, index)}>
                  {button.label}
                </div>
                
              )
            })
          }

        </div>
      </div>
  )
}

export default UserStatisticV2