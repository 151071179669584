import React from 'react';
import PropTypes from "prop-types";
import './../../../style/components/objectcontrols/smartshortfixtures.scss';
import SFS_Tip from "./SFS_Tip";

function SmartShortFixtures(props) {
    let logoimagehome = './assets/images/Teams/' + props.fixture.team_home.logo;
    let logoimageaway = './assets/images/Teams/' + props.fixture.team_away.logo;

    let matchstate = null;
    if (props.result == null) {
        let date = new Date(`${props.fixture.date}Z`);
        let dateString = date.toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
        matchstate = <div className='date_box'><span className='text15-white'>{dateString}</span></div>
    } else if (props.result != null) {
        if (props.result.is_finished != 1) {
            matchstate = (
                <div className='result_box'>
                    <span style={{marginRight: '5px'}} className='text15-red'>
                        {
                            `${props.result.ft_home_goals} : ${props.result.ft_away_goals}
                             (${props.result.ht_home_goals} : ${props.result.ht_away_goals})`
                        }
                    </span>
                    <span className='text15-yellow'>
                        {`${props.result.elapsed}'`}
                    </span>
                </div>
            );
        } else {
            matchstate = <div className='result_box'><span className='text15-white'>{props.result.ft_home_goals}:{props.result.ft_away_goals}</span></div>;
        }
    }


    let betboxfield = null;
    if (props.fixturestate == "upcoming") {
        if (props.userbidded == true) {
            betboxfield = <div className='bids_box'>
                <SFS_Tip tip={props.userfixturebid.game1_tip} odd={props.userfixturebid.game1_odd} won={props.userfixturebid.calculated} finished={false}></SFS_Tip>
                <SFS_Tip tip={props.userfixturebid.game2_tip} odd={props.userfixturebid.game2_odd} won={props.userfixturebid.calculated} finished={false}></SFS_Tip>
                <SFS_Tip tip={props.userfixturebid.game3_tip} odd={props.userfixturebid.game3_odd} won={props.userfixturebid.calculated} finished={false}></SFS_Tip>
                <SFS_Tip tip={props.userfixturebid.game4_tip} odd={props.userfixturebid.game4_odd} won={props.userfixturebid.calculated} finished={false}></SFS_Tip>
            </div>
        } else {
            betboxfield = <div className='not_bided_yellow_box'><span className='text11-white'>Make a bet</span></div>;
        }
    } else if (props.fixturestate == "live") {
        if (props.userbidded == true) {
            betboxfield = <div className='bids_box'>
                <SFS_Tip tip={props.userfixturebid.game1_tip} odd={props.userfixturebid.game1_odd} won={props.userfixturebid.calculated} finished={false}></SFS_Tip>
                <SFS_Tip tip={props.userfixturebid.game2_tip} odd={props.userfixturebid.game2_odd} won={props.userfixturebid.calculated} finished={false}></SFS_Tip>
                <SFS_Tip tip={props.userfixturebid.game3_tip} odd={props.userfixturebid.game3_odd} won={props.userfixturebid.calculated} finished={false}></SFS_Tip>
                <SFS_Tip tip={props.userfixturebid.game4_tip} odd={props.userfixturebid.game4_odd} won={props.userfixturebid.calculated} finished={false}></SFS_Tip>
            </div>
        }
    } else {
        if (props.userbidded == true) {
            betboxfield = <div className='bids_box'>
                <SFS_Tip tip={props.userfixturebid.game1_tip} odd={props.userfixturebid.game1_odd} won={props.userfixturebid.game1_odd > 0} finished={true}></SFS_Tip>
                <SFS_Tip tip={props.userfixturebid.game2_tip} odd={props.userfixturebid.game2_odd} won={props.userfixturebid.game2_odd > 0} finished={true}></SFS_Tip>
                <SFS_Tip tip={props.userfixturebid.game3_tip} odd={props.userfixturebid.game3_odd} won={props.userfixturebid.game3_odd > 0} finished={true}></SFS_Tip>
                <SFS_Tip tip={props.userfixturebid.game4_tip} odd={props.userfixturebid.game4_odd} won={props.userfixturebid.game4_odd > 0} finished={true}></SFS_Tip>
            </div>
        }
    }


    return <div>
        <div className='smart_match_short_box'>
            <div className='teams_box'>
                <div className='home_team_box'>
                    <div className='team_logo'><img className='logo' src={logoimagehome}></img></div>
                    <div className='team-name'><span className='text11-white'>{props.fixture.team_home.name}</span></div>
                </div>
                <div>{matchstate}</div>
                <div className='away_team_box'>
                    <div className='team-name'><span className='text11-white'>{props.fixture.team_away.name}</span></div>
                    <div className='team_logo'><img className='logo' src={logoimageaway}></img></div>
                </div>
            </div>
            {betboxfield}
        </div>
    </div>

}

SmartShortFixtures.propTypes = {
    fixture: PropTypes.object,
    userfixturebid: PropTypes.object,
    result: PropTypes.object,
    fixturestate: PropTypes.oneOf(['upcoming', 'live', 'finished'])
};

export default SmartShortFixtures;
