import React from 'react';
import PropTypes from "prop-types";
import './../../../style/components/objectcontrols/ssf_tipblock.scss';

function SFS_Tip(props) {

    const bidded = props.tip != undefined && props.tip.length > 0;

    let cssClassColor = null;



    if (bidded == true && props.won == true && props.finished == true) {
        cssClassColor = 'tipbox sfs_won';
    } else if (bidded == true && props.finished != true) {
        cssClassColor = 'tipbox sfs_bidded';
    } else if (bidded == true && props.won != true && props.finished == true) {
        cssClassColor = 'tipbox sfs_lost';
    } else {
        cssClassColor = 'tipbox sfs_not_bidded';
    }

    return <div className={cssClassColor}>
        <div className='typebox'><span className='text14-white'>{props.tip}</span></div>
        <div className='oddbox'><span className='text14-white'>{props.odd}</span></div>
    </div>

}

SFS_Tip.propTypes = {
    tip: PropTypes.string,
    odd: PropTypes.string,
    won: PropTypes.bool,
    finished: PropTypes.bool,
};


export default SFS_Tip;
