import React, {Fragment} from 'react';
import '../../../../style/betbook/screens/v2/home/today_fixtures_v2.scss';
import SmartContainer from "../../../components/containers/SmartContainer";
import League from "./controls/League";
import Loader from "../../../components/other/Loader";
import {BsSearch} from "react-icons/bs";
import BB_Search from "../../../components/controls/BB_Search";
import MainContainer from "../../../components/containers/MainContainer";
import BB_SmallCalendar from "../../../components/controls/BB_SmallCalendar";
import UserStatisticV2 from "../../../components/objectcontrols/v2/statistic/UserStatisticV2";
import BB_Divider from "../../../components/common/BB_Divider";
import Leaderboards from "../../../components/objectcontrols/v2/leaderboards";
import league from "./controls/League";

class TodayFixtures_V2 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      leagues: [],
      favoriteLeagues: [],
      leaguesIndexes: {},
      loaded: false,
      currentDate: new Date(),
      hideSearch: true,
      leaderboardLoaded: false,
    };

    this.allLeagues = null;
    this.leagueDateIndexes = {}
    this.allPlayerStats = []
    this.allPlayerStatsIndexes = {}
  }

  componentDidMount = () => {
    this.fetchData();
  }

  fetchData = () => {
    let dateString = this.getDateString(this.state.currentDate)

    if(! this.leagueDateIndexes[dateString]) {
      this.setState({loaded: false})
      window.apiHelper.leagues.getAllByDateAndStatsV2(dateString, (resLeagues) => {
        if(! this.state.leaderboardLoaded) {
          window.apiHelper.statistics.allStatistics((resStatistic) => {
            this.makeStatisticData(resStatistic)
            this.makeData(resLeagues)
          })
        } else {
          this.makeData(resLeagues)
        }

      })
    } else {
      this.setState({
        leagues: this.leagueDateIndexes[dateString].leagues,
        favoriteLeagues: this.leagueDateIndexes[dateString].favoriteLeagues
      })
    }
  }

  makeStatisticData = (data) => {
    data.forEach((userStat) => {
      if(! this.allPlayerStatsIndexes[userStat.user_id]) {
        this.allPlayerStatsIndexes[userStat.user_id] = {
          id: userStat.user_id,
          fullName: userStat.full_name,
          numberOfBets: userStat.numOfBets,
          country: userStat.country,
          totalScore: this.calculateTotalScore(userStat)
        }

        this.allPlayerStats.push(this.allPlayerStatsIndexes[userStat.user_id])
      }
    })

    this.allPlayerStats.sort((a, b) => {
      if(a.totalScore > b.totalScore) {
        return -1
      }

      if(a.totalScore < b.totalScore) {
        return 1
      }

      return 0
    })
  }

  calculateTotalScore = (stats) => {
    let totalScore = 0

    for (let i = 1; i <= 4; i++ ) {
      totalScore += Number.parseFloat(stats[`game${i}_sum`])
    }

    return totalScore
  }

  getDateString = (date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }

  makeData = (resLeagues, users) => {
    let leagues = []
    let favoriteLeagues = []
    let leaguesIndexes = {}

    resLeagues.forEach((league) => {
      let tempLeague = this.createLeagueItem(league, users)

      if (!leaguesIndexes[`league_${tempLeague.id}`]) {
        leaguesIndexes[`league_${tempLeague.id}`] = tempLeague
        if (tempLeague.is_favorite) {
          favoriteLeagues.push(leaguesIndexes[`league_${tempLeague.id}`])
        } else {
          leagues.push(leaguesIndexes[`league_${tempLeague.id}`])
        }
      }
    })

    leagues = this.sortLeagues(leagues)
    favoriteLeagues = this.sortLeagues(favoriteLeagues)


    this.allLeagues = leagues.concat(favoriteLeagues)
    this.leagueDateIndexes[this.getDateString(this.state.currentDate)] = {}
    this.leagueDateIndexes[this.getDateString(this.state.currentDate)].leagues = leagues
    this.leagueDateIndexes[this.getDateString(this.state.currentDate)].favoriteLeagues = favoriteLeagues

    this.setState({
      leagues,
      favoriteLeagues,
      leaguesIndexes,
      loaded: true,
      leaderboardLoaded: true,
    })

    this.updateScroll()
  }

  updateScroll = (top = false, update = false) => {
    let scrollsKey = window.localStorage.getItem('scrolls')
    let todayFixtureElement = document.getElementById('tf_main-content')
    let mainElement = document.getElementById('main-container')

    if (scrollsKey) {
      let scrolls = JSON.parse(scrollsKey)

      if (todayFixtureElement) {
        todayFixtureElement.scrollTop = scrolls.today_fixture
      }

      if (mainElement) {
        mainElement.scrollTop = scrolls.main
      }
    } else if (top) {
      if (todayFixtureElement) {
        todayFixtureElement.scrollTop = 0
      }

      if (mainElement) {
        mainElement.scrollTop = 0
      }
    }

    if (update) {
      this.forceUpdate()
    }
  }

  createLeagueItem = (league) => {
    let leagueItem = {
      id: league.id,
      api_league_id: league.api_league_id,
      name: league.name,
      logo: league.logo,
      country: league.country,
      total_fixtures: league.today_fixtures_count,
      user_favourite_leagues: league.user_favourite_league && league.user_favourite_league.length ?
          league.user_favourite_league.filter((league) => league.user_id == window.apiHelper.userInfo.id) : [],
      classes: 'league_statistic_container',
      originalClass: 'league_statistic_container',
      is_favorite: league.user_favourite_league != null &&
          league.user_favourite_league != undefined &&
          league.user_favourite_league.length &&
          league.user_favourite_league.findIndex((league) => league.user_id == window.apiHelper.userInfo.id) > -1,
      showStatistic: false,
      setShowStatistic: this.setShowStatistic,
      user_stats: league.user_stats

    }

    return leagueItem
  }

  setShowStatistic = (league, callback) => {
    this.state.leaguesIndexes[`league_${league.id}`].showStatistic = ! league.showStatistic
    this.forceUpdate()
  }

  sortLeagues = (leagues) => {
    return leagues.sort((a, b) => {
      if (a.name[0].toLowerCase() == b.name[0].toLowerCase()) {
        if (a.api_league_id > b.api_league_id) {
          return 1
        }

        if (a.api_league_id < b.api_league_id) {
          return -1
        }
      }

      if (a.country.code == null) {
        return -1
      }

      if (b.country.code == null) {
        return 1
      }

      if (a.country.name > b.country.name) {
        return 1
      }

      if (a.country.name < b.country.name) {
        return -1
      }

      return 0
    })
  }

  handleOnValueChange = (value) => {
    let leagues = [...this.allLeagues]

    leagues.forEach((league) => {
      league.classes = league.originalClass

      if (league.name.toLowerCase().indexOf(value.toLowerCase()) == -1) {
        league.classes += ' lsc_hidden'
      }
    })

    let favoriteLeagues = this.sortLeagues(leagues.filter((league) => league.is_favorite))
    let nonFavoriteLeagues = this.sortLeagues(leagues.filter((league) => !league.is_favorite))

    this.state.favoriteLeagues = favoriteLeagues
    this.state.leagues = nonFavoriteLeagues

    this.forceUpdate()
  }

  handleOnSearchClick = () => {
    this.updateScroll(true, true)
    this.setState({hideSearch: false})
  }

  handleOnTodayClick = (date) => {
    this.state.currentDate = date
    this.fetchData()
  }

  render() {
    if (!this.state.loaded) {
      return (
          <Loader/>
      )
    }

    let isFavoriteDividerVisible = this.state.favoriteLeagues.filter((league) => {
      return league.total_fixtures > 0
    }).length > 0

    return (
        <Fragment>
          <SmartContainer
              showFooter={true}
              showHeader={true}
              footerProps={{activeItem: 'timeline'}}
              headerProps={{
                title: `All leagues`,
                rightIconOnClick: () => {
                  this.handleOnSearchClick()
                },
                showBack: false,
                rightIconElement: <BsSearch color={'#fff'}/>,
              }}
          >
            <MainContainer>
              <BB_Search
                  hidden={this.state.hideSearch}
                  onChange={this.handleOnValueChange}
              />
              <BB_SmallCalendar selected={this.state.currentDate} onTodayClick={this.handleOnTodayClick}/>
              <div id={'tf_main-content'} className='tf_main-content today_fixtures'>
                <BB_Divider label={'Leaderboards'}/>
                <div style={{paddingLeft: '10px'}}>
                  <Leaderboards UserStats={this.allPlayerStats}/>
                </div>
                <BB_Divider hidden={! isFavoriteDividerVisible} label={`Favorite competitions`}/>
                {
                  this.state.favoriteLeagues.map((league) => {
                    let leagueClasses = league.showStatistic ? `${league.classes} show_statistic` : `${league.classes}`
                    let statisticClasses = league.showStatistic ? `statistic_container show_statistic` : `statistic_container`
                    return (
                        <div hidden={league.total_fixtures == 0} className={leagueClasses} key={`${league.id}`}>
                          <League League={league} Date={this.state.currentDate}/>
                          <div onClick={(e) => e.preventDefault()} className={statisticClasses}>
                            <UserStatisticV2 Stats={league.user_stats}/>
                          </div>
                        </div>
                    )
                  })
                }
                <BB_Divider label={`Other competitions`}/>
                {
                  this.state.leagues.map((league) => {
                    let classes = league.showStatistic ? `${league.classes} show_statistic` : `${league.classes}`
                    let statisticClasses = league.showStatistic ? `statistic_container show_statistic` : `statistic_container`
                    return (
                        <div hidden={league.total_fixtures == 0} className={classes} key={`${league.id}`}>
                          <League League={league} Date={this.state.currentDate}/>
                          <div className={statisticClasses}>
                            <UserStatisticV2 Stats={league.user_stats}/>
                          </div>
                        </div>
                    )
                  })
                }
              </div>
            </MainContainer>
          </SmartContainer>
        </Fragment>
    )
  }
}

export default TodayFixtures_V2;
