import React from "react";
import '../../../style/components/common/bb_avatar.scss'
import {FaUserAlt} from "react-icons/fa";

function BB_Avatar(props) {

  return (
      <div className={'avatar-wrapper'}>
        <FaUserAlt size={25} color={'#000'}/>
      </div>
  )
}

export default BB_Avatar