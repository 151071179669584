import React from 'react';
import '../../../style/betbook/league_short.scss';
import Loader from "../../components/other/Loader";
import FullContainer from "../../components/containers/FullContainer";
import LeagueShort from "../../components/objectcontrols/LeagueShort";

class CountryLeagues extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            realData: [],
            loaded: false
        };
        this.countryId = props.match.params.countryid;
    }

    componentDidMount() {
            this.getAllLeagues();
    }

    getAllLeagues(){
        window.apiHelper.leagues.getByCountryId(this.countryId,(res) => {
            if(res.leagues && res.leagues.length) {
                res.leagues.forEach((league) => {
                    if(league.user_favourite_league) {
                        league.user_favourite_league = league.user_favourite_league[0]
                    }
                })
            }

            this.setState({realData: res, loaded: true});
        });
    }

    handleImgError = (league) => {
        league.logo = 'alternative-logo.png';
        this.forceUpdate();
    };

    onStarClick = (league, i, user_favourite_league) => {
        let curLeague = this.state.realData.leagues[i];
        if(curLeague.user_favourite_league) {
            curLeague.user_favourite_league = null;
        } else {
            curLeague.user_favourite_league = {
                id: user_favourite_league,
                league_id: league.id,
                user_id: window.apiHelper.userInfo.id
            };
        }

        this.forceUpdate();
    };

    render() {

        if (this.state.loaded) {
            return (
                <FullContainer footerProps={{activeItem: 'ball'}} headerProps={{title: this.state.realData.name}}>
                    <div className='main-content'>
                        <div className='leagues-container'>
                            {this.state.realData.leagues.map((data, i) => {
                                return (
                                    <LeagueShort
                                        isChecked={data.user_favourite_league}
                                        className={data.user_favourite_league ? 'star star_checked' : 'star'}
                                        onStarClick={(user_favorite_league) => this.onStarClick(data, i, user_favorite_league)}
                                        key={data.id}
                                        league={data}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </FullContainer>
            );
        }
        else {
            return <Loader/>
        }
    }
}

export default CountryLeagues;