class UserStats {
  constructor(userStat) {
    let keys = Object.keys(userStat)

    keys.forEach((key) => {
      this[key] = userStat[key]
    })

    this.calculatePercentageAndAvgOdd(userStat)
  }

  calculatePercentageAndAvgOdd = (userStat) => {
    for (let i = 1; i < 5; i++) {
      let percentage = (Number.parseFloat(userStat[`game${i}_total_win`]) /
          Number.parseFloat(userStat[`game${i}_total`])) * 100

      // 0 / 0
      if(isNaN(percentage)) {
        this[`game${i}_percentage`] = 0
      } else {
        this[`game${i}_percentage`] = percentage.toFixed(2)
      }

      let avgOdd = Number.parseFloat(userStat[`game${i}_sum`]) / Number.parseInt(userStat[`game${i}_total_win`])
      this[`game${i}_avg_odd`] = isNaN(avgOdd) ? 0 : avgOdd.toFixed(2)
    }
  }

}

export default UserStats