import React from 'react';
import '../../../style/betbook/week-games.scss';
import Loader from "../../components/other/Loader";
import MainContainer from "../../components/containers/MainContainer";
import FullContainer from "../../components/containers/FullContainer";
import SmartContainer from "../../components/containers/SmartContainer";

import FixtureShortFixtures from "../../components/objectcontrols/FixtureShortFixtures";
import timelineSVG from '../../../style/betbook/assets/images/timeline.svg';
import leftArrowSVG from '../../../style/betbook/assets/images/Arrow.svg';
import { withRouter } from 'react-router';


class LeagueFixtures extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            realData: [],
            loaded: false,
            game: null,
        };
        this.leagudId = props.match.params.leagueid;
    }
    
    componentDidMount() {
        this.getAllFixtures();
    }

    getAllFixtures() {
        let qs = this.props.location.search
        let date = ''

        if (qs) {
            date = `&date=${qs.split('=')[1]}`
        }

        window.apiHelper.leagues.getByID(this.leagudId, window.apiHelper.userInfo.id, (res) => {
            this.setState({ realData: res, loaded: true })
        }, date);
    }

    renderGames = () => {
        return <>
            {this.state.realData.fixtures.map((fixture) =>
                <FixtureShortFixtures {...fixture} />)}
        </>
    };

    handleRightIconOnClick = () => {
        window.location.hash = `/leaderboards/?=${this.state.realData.league.id}`
    }

    handleLeftIconOnClick = () => {
        window.location.hash = '/home'
    }

    render() {
        if(this.state.loaded) return (
            <FullContainer footerProps={{activeItem: 'ball'}} headerProps={{
                title: this.state.realData.league.name,
                rightIcon: timelineSVG,
                rightIconOnClick: this.handleRightIconOnClick,
            }}>
                <div className='main-content'>
                    {this.renderGames()}
                </div>
            </FullContainer>
        );
        else {
            return <Loader/>
        }

        return (
            <SmartContainer
                showFooter={true}
                showHeader={true}
                footerProps={{activeItem: 'ball'}}
                headerProps={{
                    title: this.state.realData.league.name,
                    rightIcon: timelineSVG,
                    rightIconOnClick: this.handleRightIconOnClick,
                    leftIconOnClick: this.handleLeftIconClick,
                    showBack: true,
                }}
            >
                <MainContainer>
                    <div className='main-content'>
                        {this.renderGames()}
                    </div>
                </MainContainer>
            </SmartContainer>
        )
    }
}

export default withRouter(LeagueFixtures);
